import { Edit, NumberInput, TextInput, SimpleForm, maxValue, minValue, required } from 'react-admin';

const BadgeSystemEdit = (props) => (
  <Edit undoable={false} {...props}>
    <SimpleForm>
      <NumberInput label="Id" source="id" disabled />
      <TextInput label="Name" source="name" disabled />
      <TextInput label="Display name" source="display_name" validate={required()} />
      <NumberInput
        min={0}
        label="Achievement credit"
        source="achievement_credit"
        validate={[required(), minValue(0)]}
      />
      <NumberInput min={0} label="Streak credit" source="streak_credit" validate={[required(), minValue(0)]} />
      <NumberInput min={0} label="Content credit" source="content_credit" validate={[required(), minValue(0)]} />
      <NumberInput min={0} label="Ploom credit" source="ploom_credit" validate={[required(), minValue(0)]} />
      <NumberInput
        min={0}
        max={100}
        label="Bonus Shop discount(%)"
        source="bonus_shop_discount"
        validate={[required(), minValue(0), maxValue(100)]}
      />
    </SimpleForm>
  </Edit>
);

export default BadgeSystemEdit;
