import React, { useState, useEffect } from 'react';

const ImagePreview = ({ source }) => {
  const [url, setUrl] = useState('');

  useEffect(() => {
    const input = document.querySelector(`input[name="${source}"]`);
    const updateUrl = () => setUrl(input ? input.value : '');

    if (input) {
      setUrl(input.value);
      input.addEventListener('input', updateUrl);
    }

    return () => {
      if (input) {
        input.removeEventListener('input', updateUrl);
      }
    };
  }, [source]);

  return (
    <div style={{ marginTop: '-12px' }}>
      <div style={{ fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", marginBottom: '10px' }}>Preview</div>
      <img src={url} alt="Preview" style={{ maxWidth: '200px', maxHeight: '200px', marginBottom: '24px' }} />
    </div>
  );
};

export default ImagePreview;
