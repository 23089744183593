import React from 'react';
import { List, Datagrid, TextField } from 'react-admin';

const GamesList = (props) => (
  <List title=" Games" exporter={false} bulkActionButtons={false} sort={{ field: 'id', order: 'DESC' }} {...props}>
    <Datagrid rowClick="edit">
      <TextField label="ID" source="id" />
      <TextField label="Name" source="name" />
      <TextField label="URL" source="url" />
      <TextField source="article.name" label="Related Article" />
    </Datagrid>
  </List>
);

export default GamesList;
