import accountNumbers from '../accountNumbers';
import brands from '../brands';
import mainScreens from '../mainScreens';
import monthlyLimitations from '../monthlyLimitations';
import targetGroups from '../targetGroups';
import transactionsTypes from '../transactions/types';
import transactions from '../transactions/transactions';
import settings from '../settings';
import logs from '../logs';
import simulatorReactions from '../simulatorReactions';
import levelsPackage from '../levelsPackages';
import boosters from '../boosters';
import tiers from '../tiers';
import botChances from '../botChances';
import tiersDiscounts from '../tiersDiscounts';
import themes from '../themes';
import popup from '../popup/index';
import figureHunting from '../figureHunting';
// import baStatistics from '../baStatistics';

export default [
  brands,
  accountNumbers,
  mainScreens,
  monthlyLimitations,
  figureHunting,
  simulatorReactions,
  boosters,
  targetGroups,
  transactionsTypes,
  transactions,
  settings,
  logs,
  levelsPackage,
  tiers,
  botChances,
  tiersDiscounts,
  themes,
  popup,
  // baStatistics,
];
