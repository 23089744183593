import EventsIcon from '@material-ui/icons/Event';
import EventsCreate from './components/EventsCreate';
import EventsEdit from './components/EventsEdit';
import EventsList from './components/EventsList';
import { APP_TYPES, roles } from '../../../config/constants';

const options = {
  appTypes: [APP_TYPES.HUNGARY, APP_TYPES.IRELAND],
  allowedRoles: [roles.ADMIN],
  label: 'Events',
  subMenuName: 'dailyGames',
};

export default {
  create: EventsCreate,
  list: EventsList,
  edit: EventsEdit,
  icon: EventsIcon,
  name: 'admin/daily-game-items',
  options,
};
