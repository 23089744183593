import React from 'react';
import {
  Edit,
  SaveButton,
  SimpleForm,
  Toolbar,
  TextInput,
  ArrayInput,
  SimpleFormIterator,
  required,
  NumberInput,
} from 'react-admin';
import ImagePreview from '../../../../commonComponents/ImagePreview';

const DailyGamesEditToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

const DailyGamesEdit = (props) => (
  <Edit undoable={false} {...props}>
    <SimpleForm toolbar={<DailyGamesEditToolbar />}>
      <TextInput label="Id" source="id" disabled />
      <TextInput label="Background image url" source="background_img" validate={required()} />
      <ImagePreview source="background_img" />
      <ArrayInput source="items">
        <SimpleFormIterator disableReordering>
          <TextInput label="Image url" source="image_url" validate={required()} />
          <ImagePreview source="image_url" />
          <NumberInput label="X" source="x" validate={required()} />
          <NumberInput label="Y" source="y" validate={required()} />
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </Edit>
);

export default DailyGamesEdit;
