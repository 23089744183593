import React from 'react';
import {
  BooleanInput,
  Edit,
  NumberInput,
  required,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  ArrayInput,
  DateInput,
  FormDataConsumer,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { s3FilePaths } from '../../../../config/constants';
import ImageInput from '../../../../commonComponents/UploadInputs/ImageInput';

const useStyles = makeStyles({
  figureEventListItem: {
    display: 'flex',
    gap: '10px',
    flexWrap: 'wrap',
  },
});

const FigureHuntingGameEdit = (props) => {
  const classes = useStyles();
  return (
    <Edit undoable={false} {...props}>
      <SimpleForm>
        <ImageInput imagePath={s3FilePaths.figureGame} imageInputSource="popupp_bg" label="Popup background" />
        <TextInput label="Name" source="name"></TextInput>
        <TextInput label="Item Name" source="item_name" validate={required()}></TextInput>
        <NumberInput label="Bonus credit" source="all_figure_bonuse" validate={required()} />
        <BooleanInput label="Active" source="is_active" validate={required()} />
        <ArrayInput source="events">
          <SimpleFormIterator disableReordering>
            <TextInput label="Id" source="id" disabled />
            <DateInput label="Available From" source="available_from" />
            <TextInput label="Message" source="message"></TextInput>
            <NumberInput label="Credit" source="credit"></NumberInput>
            <NumberInput label="Brand Id" source="brand_id" validate={required()} />
            <FormDataConsumer>
              {({ getSource, scopedFormData, ...rest }) =>
                scopedFormData && (
                  <li key={scopedFormData.id} className={classes.figureEventListItem}>
                    <ImageInput
                      imagePath={s3FilePaths.figureGame}
                      imageInputSource={getSource('figure_url')}
                      label="Image"
                      scopedFormData
                      {...rest}
                    />
                  </li>
                )
              }
            </FormDataConsumer>
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Edit>
  );
};

export default FigureHuntingGameEdit;
