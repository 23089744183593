import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import BadgeSystemList from './components/BadgeSystemList';
import { roles, APP_TYPES } from '../../config/constants';
import { isHungary } from '../../helpers/appTypeHelper';
import BadgeSystemEdit from './components/BageSystemEdit';

const options = {
  appTypes: [APP_TYPES.ALL],
  allowedRoles: [roles.ADMIN, isHungary() && roles.BA],
  label: 'Badge System',
  name: 'badgeSystem',
};

export default {
  list: BadgeSystemList,
  edit: BadgeSystemEdit,
  icon: LocalOfferIcon,
  name: 'admin/badge-system',
  options,
};
