import Icon from '@material-ui/icons/Games';
import FigureHuntingGameList from './components/FigureHuntingGameList';
import FigureHuntingGameCreate from './components/FigureHuntingGameCreate';
import FigureHuntingGameEdit from './components/FigureHuntingGameEdit';
import { roles, APP_TYPES } from '../../../config/constants';
import { isHungary } from '../../../helpers/appTypeHelper';

const options = {
  appTypes: [APP_TYPES.ALL],
  allowedRoles: [roles.ADMIN, isHungary() && roles.BA],
  label: 'Game',
  subMenuName: 'figureHunting',
};

export default {
  list: FigureHuntingGameList,
  create: FigureHuntingGameCreate,
  edit: FigureHuntingGameEdit,
  icon: Icon,
  name: 'admin/figure-hunting',
  options,
};
