import React from 'react';
import { List, Datagrid, TextField, NumberField, BooleanField, Pagination } from 'react-admin';

const FigureHuntingGameList = (props) => (
  <List
    title="Games"
    pagination={<Pagination rowsPerPageOptions={[50, 100, 250]} {...props} />}
    bulkActionButtons={false}
    perPage={50}
    {...props}
  >
    <Datagrid rowClick="edit">
      <TextField label="ID" source="id" />
      <TextField label="Name" source="name" />
      <TextField label="Popup background" source="popupp_bg" />
      <NumberField label="Bonus credit" source="all_figure_bonuse" />
      <BooleanField label="Active" source="is_active" />
    </Datagrid>
  </List>
);

export default FigureHuntingGameList;
