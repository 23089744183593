import { Create, SimpleForm, TextInput, required, ArrayInput, SimpleFormIterator, NumberInput } from 'react-admin';
import { CustomDateInput } from '../../../../commonComponents/CustomDateInput';
import ImagePreview from '../../../../commonComponents/ImagePreview';

const DailyGamesCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <CustomDateInput label="Date" source="date" validate={required()} />
      <TextInput label="Background image url" source="background_img" validate={required()} />
      <ImagePreview source="background_img" />
      <ArrayInput source="items">
        <SimpleFormIterator disableReordering>
          <TextInput label="Image url" source="image_url" validate={required()} />
          <ImagePreview source="image_url" />
          <NumberInput label="X" source="x" validate={required()} />
          <NumberInput label="Y" source="y" validate={required()} />
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </Create>
);

export default DailyGamesCreate;
