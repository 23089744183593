import { stringify } from 'query-string';
import React, { useRef, useState } from 'react';
import {
  BooleanField,
  CreateButton,
  Datagrid,
  ExportButton,
  List,
  ReferenceField,
  SelectField,
  TextField,
  TopToolbar,
  useNotify,
} from 'react-admin';
import { CustomDateField } from '../../../commonComponents/DateField';
import ImageField from '../../../commonComponents/ImageField';
import CustomImportButton from '../../../commonComponents/ListActions/CustomImportButton';
import OutletAddressField from '../../../commonComponents/OutletAddressField';
import StatusField from '../../../commonComponents/StatusField';
import {
  APP_TYPES,
  CAUCASUS_TYPES,
  eSignatureStatuses,
  linearDiagramDefaultRegion,
  linearDiagramPeriodOptions,
  linearDiagramRegionFilterOptionTypes,
  roles,
  serverApiUrl,
  booleanFilterChoices,
} from '../../../config/constants';
import httpClient from '../../../core/httpClient';
import checkUserRole from '../../../helpers/checkUserRole';
import { getUserStatusesForAppType } from '../../../helpers/statusHelper';
import CardSetPicker from './CircularDiagramComponents/CardSetPicker';
import LinearDiagramPicker from './LinearDiagramComponents/LinearDiagramPicker';

import UsersFilter from './UsersFilter';

const ENV_APP_TYPE = process.env.REACT_APP_APP_TYPE;

const UserExportButton = ({ filter, customFilters }) => {
  const notify = useNotify();

  const handleClick = () => {
    const filterQueryString = `?${stringify({ ...filter, ...customFilters })}`;
    const url = `${serverApiUrl}${window.location.pathname}/xlsx${filterQueryString}`;
    const options = { method: 'GET' };

    httpClient(url, options)
      .then((res) => {
        window.location.href = res.json.url;
      })
      .catch(() => {
        notify("Error: file link wasn't loaded", 'warning');
      });
  };

  return <ExportButton exporter={handleClick} maxResults="0" />;
};

const UserListActions = ({ basePath, exporter, filterValues, customFilters, resource }) => (
  <TopToolbar>
    <CreateButton basePath={basePath} />
    {exporter && <UserExportButton filter={filterValues} customFilters={customFilters} />}
    {ENV_APP_TYPE !== APP_TYPES.HUNGARY && <CustomImportButton resource={resource} />}
  </TopToolbar>
);

const UsersList = (props) => {
  const filterRef = useRef();
  const [selectedPeriod, setSelectedPeriod] = useState(linearDiagramPeriodOptions[0].id);
  const [selectedCustomFilterOption, setSelectedCustomFilterOption] = useState({
    ...linearDiagramDefaultRegion,
    type: linearDiagramRegionFilterOptionTypes.REGION,
  });
  const [selectedFilters, setSelectedFilters] = useState([]);

  const handlePeriodChange = (event) => {
    filterRef.current.resetFilter();

    setSelectedPeriod(event.target.value);
  };

  const handleCustomFilterChange = (data) => {
    // eslint-disable-next-line no-unused-expressions
    filterRef.current?.resetFilter();

    setSelectedCustomFilterOption(data);
  };

  const handleCheckboxChange = (event) => {
    filterRef.current.resetFilter();

    const { id } = event.target;

    const index = selectedFilters.findIndex((x) => x === id);
    if (index === -1) {
      setSelectedFilters(selectedFilters.slice().concat(id));
    } else {
      setSelectedFilters(selectedFilters.slice(0, index).concat(selectedFilters.slice(index + 1)));
    }
  };

  const handleFilterChange = () => {
    setSelectedPeriod(linearDiagramPeriodOptions[0].id);
    setSelectedCustomFilterOption({
      ...linearDiagramDefaultRegion,
      type: linearDiagramRegionFilterOptionTypes.REGION,
    });
    setSelectedFilters([]);
  };

  const isBa = checkUserRole(roles.BA);
  const isAdmin = checkUserRole(roles.ADMIN);

  return (
    <>
      {(ENV_APP_TYPE === APP_TYPES.IRELAND ||
        ENV_APP_TYPE === APP_TYPES.HUNGARY ||
        CAUCASUS_TYPES.includes(ENV_APP_TYPE)) && (
        <>
          <CardSetPicker />
          <LinearDiagramPicker
            onPeriodChange={handlePeriodChange}
            onCustomFilterChange={handleCustomFilterChange}
            onCheckboxChange={handleCheckboxChange}
            selectedFilters={selectedFilters}
            selectedCustomFilterOption={selectedCustomFilterOption}
            selectedPeriod={selectedPeriod}
          />
        </>
      )}
      {ENV_APP_TYPE === APP_TYPES.BELARUS && <CardSetPicker />}

      <List
        filters={<UsersFilter permissions={props.permissions} onFilterChange={handleFilterChange} ref={filterRef} />}
        bulkActionButtons={false}
        actions={
          <UserListActions
            customFilters={{
              selected_filters: JSON.stringify(selectedFilters),
              period_type: selectedPeriod,
              custom_filter_option_id: selectedCustomFilterOption.id,
              custom_filter_option_type_id: selectedCustomFilterOption.type,
            }}
          />
        }
        filter={{
          custom_filter_option_id: selectedCustomFilterOption.id,
          custom_filter_option_type_id: selectedCustomFilterOption.type,
          period_type: selectedPeriod,
          selected_filters: JSON.stringify(selectedFilters),
        }}
        sort={{ field: 'id', order: 'DESC' }}
        {...props}
      >
        <Datagrid rowClick="edit">
          <TextField source="id" />
          <TextField source="phone" />
          {ENV_APP_TYPE !== APP_TYPES.HUNGARY && <TextField source="username" />}
          <TextField source="name" />
          <TextField source="surname" />
          <StatusField
            source={[APP_TYPES.IRELAND, APP_TYPES.HUNGARY].includes(ENV_APP_TYPE) ? 'status_custom' : 'status'}
            statuses={getUserStatusesForAppType()}
          />

          <BooleanField source="ploom" />

          {ENV_APP_TYPE === APP_TYPES.HUNGARY || CAUCASUS_TYPES.includes(ENV_APP_TYPE) ? (
            <TextField source="manager.fullName" sortable={false} />
          ) : (
            <TextField source="manager.username" sortable={false} />
          )}
          {ENV_APP_TYPE !== APP_TYPES.BELARUS && <TextField source="manager.region.name" sortable={false} />}
          {ENV_APP_TYPE !== APP_TYPES.IRELAND && (
            <ReferenceField source="city_id" reference="admin/cities" link={false} sortable={false}>
              <TextField source="name" />
            </ReferenceField>
          )}
          {ENV_APP_TYPE === APP_TYPES.BELARUS ||
          ENV_APP_TYPE === APP_TYPES.HUNGARY ||
          CAUCASUS_TYPES.includes(ENV_APP_TYPE) ? (
            <TextField source="address.outlet_code" sortable={false} />
          ) : (
            <TextField source="traffic_code" />
          )}
          {ENV_APP_TYPE === APP_TYPES.IRELAND && (
            <ReferenceField source="tier_id" reference="admin/tiers">
              <TextField source="name" />
            </ReferenceField>
          )}
          {ENV_APP_TYPE === APP_TYPES.BELARUS && isBa && <OutletAddressField label="Адрес торговой точки" />}
          {ENV_APP_TYPE === APP_TYPES.BELARUS && isBa && (
            <ReferenceField label="Юр. Лицо торговой точки" source="legal_entity_id" reference="admin/legal-entities">
              <TextField source="name" />
            </ReferenceField>
          )}

          {ENV_APP_TYPE === APP_TYPES.HUNGARY && <OutletAddressField source="address" />}
          {ENV_APP_TYPE === APP_TYPES.HUNGARY && (
            <ReferenceField source="legal_entity_id" reference="admin/legal-entities">
              <TextField source="name" />
            </ReferenceField>
          )}
          {ENV_APP_TYPE === APP_TYPES.BELARUS && !isBa && (
            <TextField label="Внешний код продавца" source="seller_code" />
          )}
          {ENV_APP_TYPE === APP_TYPES.BELARUS && isBa && (
            <TextField label="Заработанные баллы" source="coins" sortable={true} />
          )}
          {ENV_APP_TYPE === APP_TYPES.BELARUS && isBa && <TextField label="Рейтинг" source="rating" sortable={false} />}
          {ENV_APP_TYPE === APP_TYPES.BELARUS && isBa && <CustomDateField source="created_at" showTime={false} />}
          {ENV_APP_TYPE === APP_TYPES.BELARUS && <ImageField source="signature" sortable={false} />}
          {(ENV_APP_TYPE === APP_TYPES.IRELAND || ENV_APP_TYPE === APP_TYPES.HUNGARY) && (
            <TextField label="Progress bar" source="completion_rate" sortable={false} />
          )}
          {ENV_APP_TYPE === APP_TYPES.IRELAND && (
            <StatusField source="esignature.status" statuses={eSignatureStatuses} sortable={false} />
          )}

          <SelectField source="is_active_in_last_month" choices={booleanFilterChoices} />
          <CustomDateField source="last_transaction_date" showTime={false} />
          {ENV_APP_TYPE === APP_TYPES.BELARUS && isAdmin && (
            <CustomDateField source="passport.updated_at" showTime={false} />
          )}
          <CustomDateField source="first_login_date" sortable={false} showTime={false} />
          <CustomDateField source="email_last_modified_date" sortable={true} showTime={false} />
          {(ENV_APP_TYPE === APP_TYPES.BELARUS && isBa) || ENV_APP_TYPE === APP_TYPES.HUNGARY || (
            <TextField source="app_version" />
          )}
          <TextField label="Badge" source="badge_id" />
        </Datagrid>
      </List>
    </>
  );
};

export default UsersList;
