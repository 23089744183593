import React from 'react';
import { Create, required, SimpleForm, NumberInput, BooleanInput, TextInput } from 'react-admin';
import ImageInput from '../../../../commonComponents/UploadInputs/ImageInput';
import { s3FilePaths } from '../../../../config/constants';

const FigureHuntingGameCreate = (props) => (
  <Create title="Create account number" undoable={false} {...props}>
    <SimpleForm redirect="list">
      <ImageInput imagePath={s3FilePaths.figureGame} imageInputSource="popupp_bg" label="Popup background" />
      <TextInput label="Name" source="name"></TextInput>
      <TextInput label="Item Name" source="item_name" validate={required()}></TextInput>
      <NumberInput label="Bonus credit" source="all_figure_bonuse" validate={required()} />
      <BooleanInput label="Active" source="is_active" validate={required()} />
    </SimpleForm>
  </Create>
);

export default FigureHuntingGameCreate;
