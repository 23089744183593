import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { getResources, MenuItemLink, Responsive, usePermissions, useTranslate } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import onlineUsersIcon from '@material-ui/icons/Wifi';
import mediaViewIcon from '@material-ui/icons/InsertPhoto';
import HomeIcon from '@material-ui/icons/Home';
import SubscriptionsIcon from '@material-ui/icons/Subscriptions';
import ExploreIcon from '@material-ui/icons/Explore';
import AppsIcon from '@material-ui/icons/Apps';
import KnowledgeBankSubMenuIcon from '@material-ui/icons/AccountBalance';
import BonusShopIcon from '@material-ui/icons/AddShoppingCart';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import ReportsIcon from '@material-ui/icons/ChromeReaderMode';
import DailyGamesIcon from '@material-ui/icons/Schedule';
import SubMenu from './SubMenu';
import { APP_TYPES, roles } from '../../config/constants';
import { isAzerbaijan } from '../../helpers/appTypeHelper';

const useStyles = makeStyles({
  sideMenuWrapper: {
    overflowY: 'auto',
    maxHeight: 'calc(100vh - 48px - 1.5em)',
  },
});

const Menu = ({ logout }) => {
  const classes = useStyles();
  const translate = useTranslate();
  const resources = useSelector(getResources);
  const sidebarOpen = useSelector((state) => state.admin.ui.sidebarOpen);
  const { permissions: userRoles } = usePermissions();
  const [subMenuState, setSubMenuState] = useState({
    reports: false,
    bonusShop: false,
    transactions: false,
    eventMode: false,
  });

  const filterAllowedSubMenuResources = (resources, icon) => {
    if (!resources.length) {
      return resources;
    }

    const allowedResources = resources.filter((c) => {
      const roleAllowed = c.options.allowedRoles.some((r) => userRoles && userRoles.includes(r));
      const appTypeAllowed = c.options.appTypes.some((a) =>
        [process.env.REACT_APP_APP_TYPE, APP_TYPES.ALL].includes(a),
      );
      return appTypeAllowed && roleAllowed;
    });
    if (allowedResources[0]) {
      allowedResources[0].options.subMenuIcon = icon;
    }

    return allowedResources;
  };

  const handleToggle = (menu) => {
    setSubMenuState({
      ...subMenuState,
      [menu]: !subMenuState[menu],
    });
  };

  const renderMenuItem = (resource) => (
    <MenuItemLink
      key={resource.name}
      to={`/${resource.name}`}
      sidebarIsOpen={sidebarOpen}
      primaryText={(resource.options && resource.options.label) || translate(`resources.${resource.name}.name`)}
      leftIcon={<resource.icon />}
    />
  );

  const renderSubMenu = (resources) => {
    const menuOptions = resources[0].options;

    return (
      <SubMenu
        key={menuOptions.subMenuName}
        handleToggle={() => handleToggle(menuOptions.subMenuName)}
        isOpen={subMenuState[menuOptions.subMenuName]}
        sidebarIsOpen={sidebarOpen}
        name={translate(`subMenusLabels.${menuOptions.subMenuName}`)}
        icon={<menuOptions.subMenuIcon />}
      >
        {resources.map((resource) => renderMenuItem(resource))}
      </SubMenu>
    );
  };

  const determineIfResourceBelongsToAppType = (resourceAppTypes = []) =>
    resourceAppTypes.includes(APP_TYPES.ALL) || resourceAppTypes.includes(process.env.REACT_APP_APP_TYPE);

  const findSubMenuResources = (subMenuName) => {
    const subMenuResources = resources.filter(
      (resource) =>
        resource.options.subMenuName === subMenuName && determineIfResourceBelongsToAppType(resource.options.appTypes),
    );

    return subMenuResources;
  };

  const renderMenuEntry = (resource, subMenus) =>
    // eslint-disable-next-line no-nested-ternary
    resource.options.subMenuName
      ? resource.options.subMenuIcon
        ? renderSubMenu(subMenus[resource.options.subMenuName])
        : null
      : renderMenuItem(resource);
  const reportsResources = findSubMenuResources('reports');
  const winWinBonusShopResources = findSubMenuResources('winWinBonusShop');
  const outletsResources = findSubMenuResources('outlets');
  const problemsResources = findSubMenuResources('problems');
  const knowledgeBankResources = findSubMenuResources('knowledgeBank');
  const bonusShopResources = findSubMenuResources('bonusShop');
  const manageResource = findSubMenuResources('manage');
  const contentResource = findSubMenuResources('content');
  const figureHuntingResource = findSubMenuResources('figureHunting');
  const dailyGamesResource = findSubMenuResources('dailyGames');

  const subMenus = {
    reports: filterAllowedSubMenuResources(reportsResources, ReportsIcon),
    winWinBonusShop: filterAllowedSubMenuResources(winWinBonusShopResources, BonusShopIcon),
    problems: filterAllowedSubMenuResources(problemsResources, FormatListNumberedIcon),
    outlets: filterAllowedSubMenuResources(outletsResources, HomeIcon),
    knowledgeBank: filterAllowedSubMenuResources(knowledgeBankResources, KnowledgeBankSubMenuIcon),
    bonusShop: filterAllowedSubMenuResources(bonusShopResources, BonusShopIcon),
    manage: filterAllowedSubMenuResources(manageResource, SubscriptionsIcon),
    content: filterAllowedSubMenuResources(contentResource, AppsIcon),
    figureHunting: filterAllowedSubMenuResources(figureHuntingResource, ExploreIcon),
    dailyGames: filterAllowedSubMenuResources(dailyGamesResource, DailyGamesIcon),
  };

  const onlineUsersMenuItem = {
    hasList: true,
    name: 'admin/online-users',
    options: { label: 'Online users' },
    icon: onlineUsersIcon,
  };

  const mediaViewMenuItem = {
    name: 'admin/media-view',
    options: { label: 'Media View' },
    icon: mediaViewIcon,
  };

  const sidebarElement = document.getElementById('sidebar');
  const contentSectionElement = sidebarElement && sidebarElement.nextSibling && sidebarElement.nextSibling.firstChild;
  const contentSectionElementTopPosition =
    contentSectionElement && Math.abs(contentSectionElement.getBoundingClientRect().y) - window.pageYOffset;
  const contentSectionElementHeight = contentSectionElement && contentSectionElement.scrollHeight;

  const menuWrapperClass =
    (contentSectionElement &&
      contentSectionElementTopPosition + contentSectionElementHeight > document.documentElement.clientHeight) ||
    document.documentElement.clientWidth < 600
      ? ''
      : classes.sideMenuWrapper;

  return (
    <div className={menuWrapperClass}>
      {resources.map((resource) => {
        const resourceOptions = resource.options;
        const shouldRenderMenu =
          resource.hasList && resource.icon && determineIfResourceBelongsToAppType(resourceOptions.appTypes);
        return shouldRenderMenu &&
          resourceOptions.allowedRoles &&
          resourceOptions.allowedRoles.filter((v) => userRoles && userRoles.includes(v)).length
          ? renderMenuEntry(resource, subMenus)
          : null;
      })}
      {/* eslint-disable-next-line no-nested-ternary */}
      {userRoles ? (userRoles.includes(roles.ADMIN) ? renderMenuItem(onlineUsersMenuItem) : null) : null}
      {isAzerbaijan() && renderMenuItem(mediaViewMenuItem)}
      <Responsive small={logout} medium={null} />
    </div>
  );
};

export default Menu;
