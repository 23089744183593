import GamesIcon from '@material-ui/icons/Games';
import GamesCreate from './components/GamesCreate';
import GamesEdit from './components/GamesEdit';
import GamesList from './components/GamesList';
import { APP_TYPES, roles } from '../../../config/constants';

const options = {
  appTypes: [APP_TYPES.HUNGARY, APP_TYPES.IRELAND],
  allowedRoles: [roles.ADMIN],
  label: 'Games',
  subMenuName: 'dailyGames',
};

export default {
  create: GamesCreate,
  list: GamesList,
  edit: GamesEdit,
  icon: GamesIcon,
  name: 'admin/daily-games',
  options,
};
