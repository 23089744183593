import React from 'react';
import { List, Datagrid, TextField, NumberField } from 'react-admin';

const BadgeSystemList = (props) => (
  <List title="Badge System" bulkActionButtons={false} {...props}>
    <Datagrid rowClick="edit">
      <NumberField label="Id" source="id" />
      <TextField label="Name" source="name" />
      <TextField label="Display name" source="display_name" />
      <NumberField label="Achievement credit" source="achievement_credit" />
      <NumberField label="Streak credit" source="streak_credit" />
      <NumberField label="Content credit" source="content_credit" />
      <NumberField label="Ploom credit" source="ploom_credit" />
      <NumberField label="Bonus Shop discount(%)" source="bonus_shop_discount" />
    </Datagrid>
  </List>
);

export default BadgeSystemList;
