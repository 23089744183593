import React from 'react';
import { Filter, TextInput } from 'react-admin';
import { CustomDateInput } from '../../../../commonComponents/CustomDateInput';

const WordSearchGameFilter = (props) => (
  <Filter {...props}>
    <TextInput label="User ID" source="user_id" alwaysOn />
    <TextInput label="User name" source="user_name" alwaysOn />
    <TextInput label="User email" source="user_email" alwaysOn />
    <CustomDateInput label="Choose start date" source="start_date" start alwaysOn />
    <CustomDateInput label="Choose end date" source="end_date" end alwaysOn />
    <TextInput label="Type of event" source="event_type" alwaysOn />
  </Filter>
);

export default WordSearchGameFilter;
