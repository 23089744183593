import QuizIcon from '@material-ui/icons/FormatListNumbered';
import WordSearchGameList from './components/WordSearchGameList';
import { APP_TYPES, roles } from '../../../config/constants';

const options = {
  appTypes: [APP_TYPES.ALL],
  allowedRoles: [roles.ADMIN, roles.JTI_TEAM],
  label: 'Word Search Game',
  subMenuName: 'reports',
};

export default {
  list: WordSearchGameList,
  icon: QuizIcon,
  name: 'admin/reports/word-search-game',
  options,
};
