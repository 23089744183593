import React from 'react';
import {
  AutocompleteInput,
  BooleanInput,
  Create,
  NumberInput,
  ReferenceInput,
  required,
  SimpleForm,
  TextInput,
} from 'react-admin';
import { isIreland } from '../../../../helpers/appTypeHelper';

const GamesCreate = (props) => (
  <Create undoable={false} {...props}>
    <SimpleForm>
      <NumberInput label="ID" source="id" disabled />
      <TextInput label="Name" source="name" validate={required()} />
      <TextInput label="URL" source="url" validate={required()} />
      <ReferenceInput
        source="article_id"
        label="Related Article"
        allowEmpty
        reference={`admin/knowledge-bank/articles/autocomplete`}
        perPage={10000}
      >
        <AutocompleteInput />
      </ReferenceInput>
      {isIreland() && (
        <BooleanInput
          style={{ width: '300px' }}
          label="Included in the Progress bar"
          source="progress_bar_enable"
          defaultValue={true}
        />
      )}
    </SimpleForm>
  </Create>
);

export default GamesCreate;
