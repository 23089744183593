import React from 'react';
import { Datagrid, List, TextField } from 'react-admin';
import { CustomDateField } from '../../../../commonComponents/DateField';
import CustomReportListActions from '../../../../commonComponents/ListActions/CustomReportListActions';
import WordSearchGameFilter from './WordSearchGameFilter';

const WordSearchGameList = (props) => (
  <List
    title="Word Search Game reports"
    actions={<CustomReportListActions />}
    filters={<WordSearchGameFilter />}
    bulkActionButtons={false}
    sort={{}}
    {...props}
  >
    <Datagrid>
      <TextField label="ID" source="id" sortable={false} />
      <TextField label="User ID" source="user_id" sortable={false} />
      <TextField label="User Name" source="user_name" sortable={false} />
      <TextField label="User email" source="user_email" sortable={false} />
      <CustomDateField label="Date of event" source="created_at" sortable={false} />
      <TextField label="Type of event" source="event_type" sortable={false} />
      <TextField label="Metadata" source="event" sortable={false} />
      <TextField label="Level" source="level" sortable={false} />
      <TextField label="Credits" source="credits" sortable={false} />
    </Datagrid>
  </List>
);

export default WordSearchGameList;
